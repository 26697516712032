<template>
  <div class="clients">
    <div class="intro">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-9 col-md-12 col-lg-6 col-offset-1 col-md-offset-0">
            <h1 v-html="$t('navigation.privacy')"></h1>
          </div>
        </div>
      </div>

    </div>
    <div class="content content--no-tabs">
      <div class="container grid-md">
        <div class="columns">
          <div class="column col-7 col-sm-12 col-md-11 col-offset-1 col-md-offset-0">
            <div v-html="$t('privacy.intro')"></div>

            <h3 v-html="$t('privacy.part_1_headline')"></h3>
            <div v-html="$t('privacy.part_1_text')"></div>

            <h3 v-html="$t('privacy.part_2_headline')"></h3>
            <div v-html="$t('privacy.part_2_text')"></div>

            <h3 v-html="$t('privacy.part_3_headline')"></h3>
            <div v-html="$t('privacy.part_3_text')"></div>

            <h3 v-html="$t('privacy.part_4_headline')"></h3>
            <div v-html="$t('privacy.part_4_text')"></div>

            <h3 v-html="$t('privacy.part_5_headline')"></h3>
            <div v-html="$t('privacy.part_5_text')"></div>

            <h3 v-html="$t('privacy.part_6_headline')"></h3>
            <div v-html="$t('privacy.part_6_text')"></div>

            <h3 v-html="$t('privacy.part_7_headline')"></h3>
            <div v-html="$t('privacy.part_7_text')"></div>

            <h3 v-html="$t('privacy.part_8_headline')"></h3>
            <div v-html="$t('privacy.part_8_text')"></div>

            <h3 v-html="$t('privacy.part_9_headline')"></h3>
            <div v-html="$t('privacy.part_9_text')"></div>

            <h3 v-html="$t('privacy.part_10_headline')"></h3>
            <div v-html="$t('privacy.part_10_text')"></div>

            <h3 v-html="$t('privacy.part_11_headline')"></h3>
            <div v-html="$t('privacy.part_11_text')"></div>

            <h3 v-html="$t('privacy.part_12_headline')"></h3>
            <div v-html="$t('privacy.part_12_text')"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Imprint',
  components: {},
}
</script>

<style lang="scss" scoped>
  .intro {
    padding: 2rem 0 4rem 0;
  }

  .content {
    background: $color-primary-50;
  }
</style>
